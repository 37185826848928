import { useEffect, useRef } from "react";

import { createContext, useContext, useState } from "react";

import { IUser } from "../components/interfaces";

import { getUserData } from "../api";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
	const [userData, setUserData] = useState<IUser>(null);

	const userDataRef = useRef(userData);

	useEffect(() => {
		// Update the ref every time userData changes
		userDataRef.current = userData;
	}, [userData]);

	useEffect(() => {
		const fetchData = async () => {
			const token = localStorage.getItem("token");

			if (token) {
				const newData = await getUserData();
				const newInvoices = newData.invoices || [];

				const currentInvoices =
					userDataRef.current && userDataRef.current.invoices
						? userDataRef.current.invoices
						: [];

				// Retain invoices that are in current userData but not in newInvoices
				const retainedInvoices = currentInvoices.filter(
					(invoice) => !newInvoices.map((i) => i.pid).includes(invoice.pid)
				);

				// Combine retained invoices with new invoices
				newData.invoices = [...retainedInvoices, ...newInvoices];

				localStorage.setItem("userData", JSON.stringify(newData));
				setUserData(newData);
			}
		};

		// Load initially
		fetchData();

		// Set up the interval
		const intervalId = setInterval(fetchData, 30000);

		// Clean up the interval on component unmount
		return () => clearInterval(intervalId);
	}, []); // Remove userData from dependencies

	return (
		<UserContext.Provider value={{ userData, setUserData }}>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => useContext(UserContext);
