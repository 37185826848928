import * as React from "react";

import {
	DetailsList,
	DetailsListLayoutMode,
	IColumn,
	Modal,
	DetailsRow,
	IDetailsRowProps,
} from "@fluentui/react";

import styles from "./TariffCallout.module.css";
import { ITariff, ITariffRecord } from "../interfaces";
import { useEffect } from "react";
import { getTariff } from "../../api";

interface ITariffCalloutProps {
	toggleIsCalloutVisible: () => void;
	tariffCode: string;
}

export const TariffCallout: React.FC<ITariffCalloutProps> = (props) => {
	const [tariffTable, setTariffTable] = React.useState<ITariffRecord[]>([]);

	const columns: IColumn[] = [
		{
			key: "heading",
			name: "Heading",
			fieldName: "heading",
			minWidth: 80,
			maxWidth: 80,
			data: "string",
		},
		{
			key: "statsCode",
			name: "Stat Code",
			fieldName: "statsCode",
			minWidth: 80,
			maxWidth: 80,
			data: "string",
		},
		{
			key: "statsUnit",
			name: "Stat Unit",
			fieldName: "statsUnit",
			minWidth: 80,
			maxWidth: 80,
			data: "string",
		},
		{
			key: "description",
			name: "Description",
			fieldName: "description",
			minWidth: 300,
			maxWidth: 300,
			data: "string",
			onRender: (i: ITariffRecord) => {
				if (i.heading.length < 6 && i.heading.length > 0) {
					return (
						<div className={styles.textWrap}>
							<b>{i.description.replace(/<[^>]*>/g, "")}</b>
						</div>
					);
				}
				if (!i.statsCode && i.heading.length > 5) {
					return (
						<div className={styles.textWrap}>
							<b>
								<i>{i.description.replace(/<[^>]*>/g, "")}</i>
							</b>
						</div>
					);
				}
				if (!i.heading) {
					return (
						<div className={styles.textWrap}>
							<i>{i.description.replace(/<[^>]*>/g, "")}</i>
						</div>
					);
				}
				return (
					<div className={styles.textWrap}>
						{i.description.replace(/<[^>]*>/g, "")}
					</div>
				);
			},
		},
		{
			key: "normalTariff",
			name: "Norm Tariff",
			fieldName: "normalTariff",
			minWidth: 60,
			maxWidth: 60,
			data: "string",
		},
		{
			key: "prefTariff",
			name: "Pref Tariff",
			fieldName: "prefTariff",
			minWidth: 100,
			maxWidth: 100,
			data: "string",
			onRender: (i: ITariffRecord) => {
				return (
					<div className={styles.textWrap}>
						{i.prefTariff.replace(/<br>/g, "\n")}
					</div>
				);
			},
		},
	];

	const loadTable = async () => {
		const response = await getTariff(props.tariffCode);
		setTariffTable(response);
	};

	useEffect(() => {
		try {
			loadTable();
		} catch (error) {
			console.error(error);
		}
	}, [props.tariffCode]);

	const onRenderDetailsHeader = (headerProps, defaultRender) => {
		if (!headerProps || !defaultRender) {
			return null;
		}
		return defaultRender({
			...headerProps,
			styles: {
				root: {
					selectors: {
						".ms-DetailsHeader-cell": {
							whiteSpace: "normal",
							textOverflow: "clip",
							lineHeight: "normal",
						},
						".ms-DetailsHeader-cellTitle": {
							height: "100%",
							alignItems: "center",
						},
					},
				},
			},
		});
	};

	const onRenderRow = (rowProps: IDetailsRowProps | undefined): JSX.Element => {
		if (rowProps) {
			if (rowProps.item.searchCode === props.tariffCode) {
				return <DetailsRow {...rowProps} className={styles.selectedTariff} />;
			}
			return <DetailsRow {...rowProps} />;
		}

		return <></>;
	};

	return (
		<div className={styles.tariffCalloutContainer}>
			<Modal
				isOpen={true}
				isBlocking={false}
				className={styles.callout}
				onDismiss={props.toggleIsCalloutVisible}
			>
				<div className={styles.tariffListContainer}>
					<DetailsList
						compact={true}
						className={styles.tariffsList}
						items={tariffTable}
						columns={columns}
						selectionMode={0}
						layoutMode={DetailsListLayoutMode.justified}
						isHeaderVisible={true}
						onRenderDetailsHeader={onRenderDetailsHeader}
						onRenderRow={onRenderRow}
					/>
				</div>
			</Modal>
		</div>
	);
};
