import * as React from "react";
import {
	DefaultButton,
	DetailsList,
	DetailsListLayoutMode,
	IColumn,
	IconButton,
	IDetailsRowProps,
	DetailsRow,
	Spinner,
} from "@fluentui/react";

import { IInvoice, IUser } from "../../components/interfaces";
import {
	deleteInvoiceApi,
	processInvoiceApi,
	downloadFileApi,
	readInvoiceApi,
	getUserData,
} from "../../api/api";

import { NewInvoiceDialog } from "../../components/NewInvoiceDialog";
import { EditInvoiceDialog } from "../../components/EditInvoiceDialog";

export interface IMyInvoicesWindowState {
	columns: IColumn[];
	items: IInvoice[];
	newInvoiceDialogHidden: boolean;
	editInvoiceDialogHidden: boolean;
	viewInvoiceDialogHidden: boolean;
	invoiceToEdit: IInvoice | null;
	invoiceToView: IInvoice | null;
	loading: boolean;
}

export interface IMyInvoicesWindowProps {
	userData: IUser;
	setUserData: (userData: IUser) => void;
}

import styles from "./MyInvoices.module.css";

export class MyInvoicesWindow extends React.Component<
	IMyInvoicesWindowProps,
	IMyInvoicesWindowState
> {
	constructor(props: IMyInvoicesWindowProps) {
		super(props);

		// Define the columns
		const columns: IColumn[] = [
			{
				key: "filename",
				name: "File Name",
				fieldName: "filename",
				minWidth: 120,
				maxWidth: 200,
				data: "string",
				isPadded: true,
			},
			{
				key: "invoice_number",
				name: "Invoice #",
				fieldName: "invoice_number",
				minWidth: 80,
				data: "string",
				isPadded: true,
			},
			{
				key: "status",
				name: "Status",
				fieldName: "status",
				minWidth: 80,
				data: "string",
				isPadded: true,
			},
			{
				key: "company",
				name: "Company",
				fieldName: "company",
				minWidth: 120,
				data: "number",
				onRender: (invoice: IInvoice) => {
					return invoice.company && <span>{invoice.company.name}</span>;
				},
				isPadded: true,
			},
			{
				key: "created",
				name: "Created",
				fieldName: "created",
				minWidth: 120,
				maxWidth: 150,
				data: "string",
				onRender: (invoice: IInvoice) => {
					return <span>{invoice.created_at}</span>;
				},
				isPadded: true,
			},
			{
				key: "finished",
				name: "Finished",
				fieldName: "finished",
				minWidth: 120,
				maxWidth: 150,
				data: "string",
				onRender: (invoice: IInvoice) => {
					return (
						invoice.status === "processed" && <span>{invoice.updated_at}</span>
					);
				},
				isPadded: true,
			},
			{
				key: "items",
				name: "Items",
				fieldName: "items",
				minWidth: 40,
				data: "number",
				onRender: (invoice: IInvoice | null) => {
					return invoice
						? invoice.items.length > 0 && (
								<span>
									{
										invoice.items.filter((item) => item.status === "processed")
											.length
									}
									/{invoice.items.length}
								</span>
						  )
						: null;
				},
				isPadded: true,
			},
			{
				key: "actions",
				name: "Actions",
				fieldName: "actions",
				minWidth: 140,
				maxWidth: 160,
				data: "icon",
				onRender: (item: IInvoice) => {
					return (
						<div>
							<IconButton
								iconProps={{ iconName: "Download" }}
								title="Download"
								ariaLabel="Download"
								disabled={item.status !== "processed"}
								onClick={() => this.downloadInvoice(item)}
							/>
							<IconButton
								iconProps={{ iconName: "EditNote" }}
								title="Edit"
								ariaLabel="Edit"
								disabled={
									!["scanned", "processed", "error"].includes(item.status)
								}
								onClick={() => {
									this.toggleEditInvoiceDialog();
									this.setState({ invoiceToEdit: item });
									this.readInvoice(item);
								}}
							/>
							<IconButton
								iconProps={{ iconName: "Play" }}
								title="Process"
								ariaLabel="Process"
								disabled={item.status !== "scanned"}
								onClick={() => this.processInvoice(item)}
							/>
							<IconButton
								iconProps={{ iconName: "Delete" }}
								title="Delete"
								ariaLabel="Delete"
								// disabled={item.status === "scanning"}
								onClick={() => this.deleteInvoice(item)}
							/>
						</div>
					);
				},
				isPadded: true,
			},
		];

		this.state = {
			items: this.props.userData.invoices ? this.props.userData.invoices : [],
			columns,
			newInvoiceDialogHidden: true,
			editInvoiceDialogHidden: true,
			viewInvoiceDialogHidden: true,
			invoiceToEdit: null,
			invoiceToView: null,
			loading: false,
		};
	}

	componentDidUpdate(prevProps: IMyInvoicesWindowProps) {
		if (prevProps.userData.invoices !== this.props.userData.invoices) {
			this.setState({ items: this.props.userData.invoices });
		}
	}
	public toggleNewInvoiceDialog = (): void => {
		this.setState((prevState) => ({
			newInvoiceDialogHidden: !prevState.newInvoiceDialogHidden,
		}));
	};

	updateInvoiceStatus = (invoice: IInvoice, status: string) => {
		const processInvoiceResult = {
			...invoice,
			status: status,
		};
		const userData = {
			...this.props.userData,
			invoices: this.props.userData.invoices.map((i) =>
				i.pid === invoice.pid ? processInvoiceResult : i
			),
		};

		this.props.setUserData(userData);
	};

	refreshData = async () => {
		try {
			this.setState({ loading: true });
			const data = await getUserData();
			this.props.setUserData(data);
			this.setState({ loading: false });
		} catch (error) {
			console.error(error);
			this.setState({ loading: false });
		}
	};

	processInvoice = async (invoice: IInvoice) => {
		try {
			this.updateInvoiceStatus(invoice, "processing");
			const response = await processInvoiceApi({
				invoice_pid: invoice.pid,
			});
		} catch (error) {
			this.updateInvoiceStatus(invoice, "error");
			console.error("Error handling create:", error);
		}
	};

	downloadInvoice = async (invoice: IInvoice) =>
		downloadFileApi({
			pid: invoice.pid,
			filename: invoice.filename,
			file_extension: "csv",
		});

	deleteInvoice = async (invoice: IInvoice) => {
		try {
			const result = await deleteInvoiceApi({
				invoice_pid: invoice.pid,
			});
			const userDataDel = {
				...this.props.userData,
				invoices: this.props.userData.invoices.filter(
					(i) => i.pid !== invoice.pid
				),
			};
			this.props.setUserData(userDataDel);
		} catch (error) {
			console.error("Error handling create:", error);
		}
	};

	readInvoice = async (invoice: IInvoice) => {
		readInvoiceApi({ invoice_pid: invoice.pid });
		this.setState((prevState) => ({
			items: prevState.items.map((i) =>
				i.pid === invoice.pid ? { ...i, is_read: true } : i
			),
		}));
	};

	public toggleEditInvoiceDialog = (): void => {
		this.setState((prevState) => ({
			editInvoiceDialogHidden: !prevState.editInvoiceDialogHidden,
		}));
	};
	public toggleViewInvoiceDialog = (): void => {
		this.setState((prevState) => ({
			viewInvoiceDialogHidden: !prevState.viewInvoiceDialogHidden,
		}));
	};

	onRenderRow = (rowProps: IDetailsRowProps | undefined): JSX.Element => {
		if (rowProps) {
			if (rowProps.item.status === "scanning") {
				return <DetailsRow {...rowProps} className={styles.scanningRow} />;
			}
			if (rowProps.item.status === "processing") {
				return <DetailsRow {...rowProps} className={styles.processingRow} />;
			}
			if (rowProps.item.status === "error") {
				return (
					<DetailsRow
						{...rowProps}
						className={
							rowProps.item.is_read
								? styles.errorRowRead
								: styles.errorRowUnread
						}
					/>
				);
			}
			if (rowProps.item.status === "processed") {
				return (
					<DetailsRow
						{...rowProps}
						className={
							rowProps.item.is_read
								? styles.processedRowRead
								: styles.processedRowUnread
						}
					/>
				);
			}
			if (rowProps.item.status === "scanned") {
				return (
					<DetailsRow
						{...rowProps}
						className={
							rowProps.item.is_read
								? styles.scannedRowRead
								: styles.scannedRowUnread
						}
					/>
				);
			}

			return <DetailsRow {...rowProps} />;
		}

		return <></>;
	};

	public render() {
		const { columns, items } = this.state;
		return (
			<div className={styles.invoicesContainer}>
				{" "}
				<NewInvoiceDialog
					hidden={this.state.newInvoiceDialogHidden}
					setHidden={this.toggleNewInvoiceDialog}
					userData={this.props.userData}
					setUserData={this.props.setUserData}
				/>
				{this.state.invoiceToEdit && (
					<EditInvoiceDialog
						hidden={this.state.editInvoiceDialogHidden}
						setHidden={this.toggleEditInvoiceDialog}
						invoiceToEdit={this.state.invoiceToEdit}
						userData={this.props.userData}
						setUserData={this.props.setUserData}
					/>
				)}
				{this.state.loading ? (
					<div className={styles.loading}>
						<Spinner />
					</div>
				) : (
					<div className={styles.buttonsContainer}>
						<IconButton
							iconProps={{ iconName: "Refresh" }}
							title="Refresh"
							ariaLabel="Refresh"
							onClick={this.refreshData}
							className={styles.refreshButton}
						/>
						<DefaultButton
							onClick={this.toggleNewInvoiceDialog}
							text="Upload Invoice"
							className={styles.uploadInvoiceButton}
						/>
					</div>
				)}
				<DetailsList
					items={items.sort((a, b) => {
						let aN = new Date(a.created_at);
						let bN = new Date(b.created_at);
						return bN.getTime() - aN.getTime();
					})}
					columns={columns}
					selectionMode={0}
					layoutMode={DetailsListLayoutMode.justified}
					isHeaderVisible={true}
					onRenderRow={this.onRenderRow}
				/>
			</div>
		);
	}
}
