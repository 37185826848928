import React from "react";
import {
	ComposedChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Line,
	ResponsiveContainer,
	Dot,
} from "recharts";

import styles from "./InvoicesChart.module.css";

interface IInvoicesChartProps {
	invoices: {
		[key: string]: {
			count: number;
			itemSum: number;
		};
	};
}

export class InvoicesChart extends React.Component<IInvoicesChartProps> {
	constructor(props) {
		super(props);
		this.state = {
			invoices: props.invoices,
			// ... other state properties ...
		};
	}

	processData = () => {
		const { invoices } = this.props;
		return Object.entries(invoices).map(([date, data], index) => {
			return {
				date: date.split(" ").slice(0, 2).join(" "),
				count: data.count,
				avgItems: data.itemSum / data.count || 0,
			};
		});
	};

	public render() {
		const data = this.processData();
		return (
			<div className={styles.rootStyle}>
				<h4 className={styles.chartTitle}>Invoices</h4>
				<ResponsiveContainer className={styles.chartContainer}>
					<ComposedChart data={data}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey={"date"} tickCount={7} />
						<YAxis />
						<Tooltip />
						<Bar
							dataKey="count"
							fill="#8884d8"
							name="Total number of invoices"
						/>
						<Line
							type="monotone"
							dataKey="avgItems"
							name="Avg items per invoice"
							stroke="#82ca9d"
							dot={(props) => {
								if (props.value > 0) {
									return <Dot {...props} fill="#82ca9d" />;
								}
								return null;
							}}
						/>
					</ComposedChart>
				</ResponsiveContainer>
			</div>
		);
	}
}
