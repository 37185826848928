import { useState, useEffect } from "react";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import isEqual from "lodash.isequal";
import { useBoolean } from "@fluentui/react-hooks";
import {
	mergeStyleSets,
	DialogFooter,
	DefaultButton,
	PrimaryButton,
	TextField,
	DetailsList,
	DetailsListLayoutMode,
	IColumn,
	IconButton,
	MessageBar,
	MessageBarType,
	Modal,
	Callout,
	ITooltipHostStyles,
	Text,
	DelayedRender,
	MaskedTextField,
	Icon,
} from "@fluentui/react";

import { updateInvoiceApi } from "../../api";
import { IInvoice, IItem, IUser } from "../../components/interfaces";
import { TariffCallout } from "../TariffCallout";
import styles from "./EditInvoiceDialog.module.css";
import { ITariff } from "../../components/interfaces";

const tooltipStyles: Partial<ITooltipHostStyles> = {
	root: { display: "inline-block", zIndex: 9999 },
};

const editInvoiceWindowStyle = mergeStyles({
	maxWidth: "100%",
	backgroundColor: "rgba(255, 255, 255, 0.5)",
	zIndex: 1000,
});

const editInvoiceContentStyle = mergeStyles({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	margin: 20,
	padding: 20,
	borderRadius: 4,
	backgroundColor: "rgba(255, 255, 255, 0.5)",
	boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)", // Optional: for shadow
	background: "white", // Or any other background color for the popup
});

const calloutStyles = mergeStyleSets({
	button: {
		width: 130,
	},
	callout: {
		width: "30%",
		padding: "20px 24px",
		backgroundColor: "white",
	},
});

interface IEditInvoiceDialogProps {
	hidden: boolean;
	setHidden: React.Dispatch<React.SetStateAction<boolean>>;
	invoiceToEdit: IInvoice;
	userData: IUser;
	setUserData: (userData: IUser) => void;
}

export const EditInvoiceDialog: React.FC<IEditInvoiceDialogProps> = (props) => {
	const [errors, setErrors] = useState([]);
	const [editedInvoice, setEditedInvoice] = useState<IInvoice>(
		props.invoiceToEdit
	);
	const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
		useBoolean(false);

	const [calloutTariff, setCalloutTariff] = useState<string | null>(null);

	const [editedPID, setEditedPID] = useState(null);
	const [editedDescription, setEditedDescription] = useState(null);
	const [editedPrice, setEditedPrice] = useState(null);
	const [editedQuantity, setEditedQuantity] = useState(null);
	const [editedTariff, setEditedTariff] = useState<string | null>(null);

	useEffect(() => {
		if (!isEqual(editedInvoice, props.invoiceToEdit)) {
			setEditedInvoice(props.invoiceToEdit);
		}
		setErrors([]);
	}, [props.invoiceToEdit]);

	const resetEdited = () => {
		setEditedPID(null);
		setEditedDescription(null);
		setEditedPrice(null);
		setEditedQuantity(null);
		setEditedTariff(null);
	};

	const submitEditedItem = () => {
		setEditedInvoice({
			...editedInvoice,
			items: editedInvoice.items.map((item) =>
				item.pid === editedPID
					? {
							...item,
							description: editedDescription
								? editedDescription
								: item.description,
							price: editedPrice ? editedPrice : item.price,
							quantity: editedQuantity ? editedQuantity : item.quantity,
							tariffCode: editedTariff ? editedTariff : item.tariffCode,
					  }
					: item
			),
		});
		resetEdited();
	};

	const columns: IColumn[] = [
		{
			key: "description",
			name: "Decsription",
			fieldName: "description",
			isRowHeader: true,
			minWidth: 300,
			maxWidth: 200,
			data: "string",
			onRender: (i: IItem) => {
				if (editedPID == i.pid) {
					return (
						<TextField
							value={editedDescription}
							onChange={(event, newValue) => {
								if (newValue !== editedDescription) {
									setEditedDescription(newValue);
								}
							}}
						/>
					);
				} else {
					return <span>{i.description}</span>;
				}
			},
		},
		{
			key: "price",
			name: "Price",
			fieldName: "price",
			isRowHeader: true,
			minWidth: 100,
			data: "number",
			onRender: (i: IItem) => {
				if (editedPID == i.pid) {
					return (
						<TextField
							value={editedPrice?.toString() || null}
							onChange={(event, newValue) => {
								if (newValue !== editedPrice?.toString() || null) {
									setEditedPrice(newValue);
								}
							}}
							type="number"
						/>
					);
				} else {
					return <span>{i.price}</span>;
				}
			},
		},
		{
			key: "quantity",
			name: "Quantity",
			fieldName: "quantity",
			isRowHeader: true,
			minWidth: 100,
			data: "number",
			onRender: (i: IItem) => {
				if (editedPID == i.pid) {
					return (
						<TextField
							value={editedQuantity?.toString() || null}
							onChange={(event, newValue) => {
								if (newValue !== editedQuantity?.toString() || null) {
									setEditedQuantity(newValue);
								}
							}}
							type="number"
						/>
					);
				} else {
					return <span>{i.quantity}</span>;
				}
			},
		},
		{
			key: "total",
			name: "Total",
			fieldName: "total",
			isRowHeader: true,
			minWidth: 100,
			data: "number",
			onRender: (i: IItem) => {
				return <span>{Math.round(i.price * i.quantity * 100) / 100}</span>;
			},
		},
		{
			key: "status",
			name: "Status",
			fieldName: "status",
			minWidth: 80,
			isRowHeader: true,
			data: "string",
			onRender: (i: IItem) => {
				return <span>{i.status}</span>;
			},
		},
		{
			key: "updated",
			name: "Updated",
			fieldName: "updated",
			minWidth: 120,
			maxWidth: 150,
			data: "string",
			onRender: (i: IItem) => {
				return <span>{i.updated_at}</span>;
			},
		},
		{
			key: "tariff",
			name: "Tariff",
			fieldName: "tariff",
			minWidth: 150,
			maxWidth: 150,
			onRender: (i: IItem) => {
				return (
					<div>
						{(i.tariffCode || i.status == "error") &&
							(editedPID == i.pid ? (
								<MaskedTextField
									value={editedTariff || "9999999999a"}
									onChange={(event, newValue) => {
										if (newValue !== editedTariff) {
											setEditedTariff(newValue);
										}
									}}
									mask="9999999999a"
									maskChar=" "
									maskFormat={{
										"9": /[0-9]/, // 9 will allow digits
										a: /[A-Za-z]/, // a will allow a single letter
									}}
								/>
							) : (
								<div>
									{i.status == "processed" && (
										<div>
											{i.tariffCode || null}{" "}
											{i.tariffCode && (
												<span
													onClick={() => {
														toggleIsCalloutVisible();
														setCalloutTariff(i.tariffCode);
													}}
													id={`callout-${i.tariffCode}`}
													className={calloutStyles.button}
												>
													<Icon
														iconName="Info"
														className={styles.cursorPointer}
													/>
												</span>
											)}
										</div>
									)}
									{i.status == "error" && <span>{i.tariffCode || null}</span>}
								</div>
							))}
					</div>
				);
			},
		},
		{
			key: "actions",
			name: "Actions",
			fieldName: "actions",
			minWidth: 90,
			maxWidth: 90,
			isResizable: true,
			data: "icon",
			onRender: (i: IItem) => {
				return (
					<div>
						{editedPID !== i.pid && (
							<IconButton
								iconProps={{ iconName: "Edit" }}
								title="Edit"
								ariaLabel="Edit"
								onClick={() => {
									editedPID !== null && submitEditedItem();
									setEditedPID(i.pid);
									setEditedDescription(i.description);
									setEditedPrice(i.price);
									setEditedQuantity(i.quantity);
									setEditedTariff(i.tariffCode);
								}}
							/>
						)}
						{editedPID === i.pid && (
							<IconButton
								iconProps={{ iconName: "Accept" }}
								title="Accept"
								ariaLabel="Accept"
								onClick={() => {
									editedPID !== null && submitEditedItem();
								}}
							/>
						)}
						<IconButton
							iconProps={{ iconName: "Delete" }}
							title="Delete"
							ariaLabel="Delete"
							onClick={() =>
								setEditedInvoice({
									...editedInvoice,
									items: editedInvoice.items.filter(
										(item) => item.pid !== i.pid
									),
								})
							}
						/>
					</div>
				);
			},
			isPadded: true,
		},
	];

	const validateForm = () => {
		let errs = [];
		editedInvoice.items.forEach((item) => {
			if (item.description.trim() === "") {
				errs.push("Description cannot be empty");
			}
		});
		if (errs.length > 0) {
			setErrors(errs);
			return false;
		}
		return true;
	};

	const handleSave = async () => {
		if (!validateForm()) {
			return;
		}
		try {
			const response = await updateInvoiceApi(editedInvoice);
			if (!response.ok) {
				const htmlText = await response.text();
				const parser = new DOMParser();
				const doc = parser.parseFromString(htmlText, "text/html");
				const pElement = doc.querySelector("p");
				const errorMessage = pElement ? pElement.textContent : "Unknown error";
				throw new Error(errorMessage);
			}
			const data = await response.json();
			const userData = {
				...props.userData,
				invoices: props.userData.invoices.map((i) =>
					i.pid === editedInvoice.pid ? data : i
				),
			};
			props.setUserData(userData);
			props.setHidden(true);
		} catch (error) {
			console.error(error);
			setErrors([error.message]);
		}
	};

	return (
		<Modal
			isOpen={!props.hidden}
			onDismiss={() => {
				props.setHidden(true);
				setEditedInvoice(props.invoiceToEdit);
			}}
			isBlocking={false}
			containerClassName={editInvoiceWindowStyle}
		>
			<div className={editInvoiceContentStyle}>
				{errors.length > 0 &&
					errors.map((error) => (
						<MessageBar
							messageBarType={MessageBarType.error}
							isMultiline={false}
							dismissButtonAriaLabel="Close"
							id={error}
						>
							{error}
						</MessageBar>
					))}
				{isCalloutVisible && calloutTariff && (
					<TariffCallout
						toggleIsCalloutVisible={toggleIsCalloutVisible}
						tariffCode={calloutTariff}
					/>
				)}
				<DetailsList
					items={editedInvoice ? editedInvoice.items : []}
					columns={columns}
					selectionMode={0}
					layoutMode={DetailsListLayoutMode.justified}
					isHeaderVisible={true}
				/>
				<DialogFooter>
					<PrimaryButton onClick={handleSave} text="Save" />
					<DefaultButton
						onClick={() => {
							props.setHidden(true);
							setEditedInvoice(props.invoiceToEdit);
							resetEdited();
						}}
						text="Cancel"
					/>
				</DialogFooter>
			</div>
		</Modal>
	);
};
