import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { initializeIcons } from "@fluentui/react";
import "./index.css";

import Layout from "./pages/layout/Layout";
import Office from "./pages/Office/Office";
import Usage from "./pages/Usage/Usage";

initializeIcons();

const router = createHashRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Office />,
			},
			{
				path: "/usage",
				element: <Usage />,
			},
		],
	},
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<UserProvider>
			<RouterProvider router={router} />
		</UserProvider>
	</React.StrictMode>
);
