import { DefaultButton } from "@fluentui/react";
import styles from "./LoginButton.module.css";
import { useUser } from "../../contexts/UserContext";

export const LoginButton = ({ isLogged, setIsLogged, setShowLoginDialog }) => {
	const { setUserData } = useUser();
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("userData");
		setUserData(null);
		setIsLogged(false);
	};
	return (
		<DefaultButton
			text={isLogged ? "Logout" : "Login"}
			className={styles.loginButton}
			onClick={() => {
				isLogged ? handleLogout() : setShowLoginDialog(true);
			}}
		></DefaultButton>
	);
};
