import { useState, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { Nav, INavLink, INavStyles, INavLinkGroup } from "@fluentui/react";
import { LoadingComponent } from "../../components/LoadingComponent";
import { getUserData } from "../../api";
import { MyInvoicesWindow } from "../MyInvoices";

import styles from "./Office.module.css";

const navStyles: Partial<INavStyles> = {
	root: {
		width: 208,
		height: 350,
		boxSizing: "border-box",
		border: "1px solid #eee",
		overflowY: "auto",
	},
};

const navLinkGroups: INavLinkGroup[] = [
	{
		links: [
			{
				name: "Dashboard",
				url: "",
				key: "dashboard",
				icon: "ViewDashboard",
				target: "_blank",
				disabled: true,
			},
			{
				name: "My Invoices",
				url: "",
				key: "invoices",
				icon: "Dictionary",
				target: "_blank",
			},
		],
	},
];

export const OfficeMenu: React.FunctionComponent<{
	activePage: string;
	setActivePage: React.Dispatch<React.SetStateAction<string>>;
}> = ({ activePage, setActivePage }) => {
	return (
		<Nav
			onLinkClick={(ev, item) => _onLinkClick(ev, item, setActivePage)}
			selectedKey={activePage}
			ariaLabel="Nav basic example"
			styles={navStyles}
			groups={navLinkGroups}
		/>
	);
};

const ActivePage = ({ activePage, userData, setUserData }) => {
	switch (activePage) {
		case "invoices":
			return <MyInvoicesWindow userData={userData} setUserData={setUserData} />;
		default:
			return null; // or some default component or behavior
	}
};

function _onLinkClick(
	ev?: React.MouseEvent<HTMLElement>,
	item?: INavLink,
	setActivePage?: React.Dispatch<React.SetStateAction<string>>
) {
	ev.preventDefault();
	setActivePage && setActivePage(item.key);
}

const Office = () => {
	const [activePage, setActivePage] = useState("invoices");
	const { userData, setUserData } = useUser();

	return (
		<div className={styles.container}>
			{/* <OfficeMenu activePage={activePage} setActivePage={setActivePage} /> */}
			{userData ? (
				<ActivePage
					activePage={activePage}
					userData={userData}
					setUserData={setUserData}
				/>
			) : (
				<LoadingComponent />
			)}
		</div>
	);
};

export default Office;
