import { useState } from "react";
import {
	Dialog,
	MessageBar,
	MessageBarType,
	TextField,
	PrimaryButton,
	DefaultButton,
	DialogFooter,
} from "@fluentui/react";
import { createInvoiceApi } from "../../api";
import { IUser } from "../../components/interfaces";

interface INewInvoiceDialogProps {
	hidden: boolean;
	setHidden: React.Dispatch<React.SetStateAction<boolean>>;
	userData: IUser;
	setUserData: (userData: IUser) => void;
}

export type FileWithBuffer = {
	file: File;
	arrayBuffer: ArrayBuffer;
};

export const NewInvoiceDialog: React.FC<INewInvoiceDialogProps> = (props) => {
	const [filesUpload, setFilesUpload] = useState<FileWithBuffer[]>([]);

	const getPlaceholderInvoice = (filename: string, invoice_pid: string) => {
		let dt = new Date().toISOString();
		return {
			pid: invoice_pid,
			invoice_number: "",
			company: null,
			items: [],
			filename: filename,
			status: "scanning",
			created_at: dt,
			updated_at: dt,
			is_read: false,
		};
	};

	const handleSave = async () => {
		for (const file of filesUpload) {
			try {
				const response = await createInvoiceApi({
					filename: file.file.name,
					content: file.arrayBuffer,
				});
				const userData = {
					...props.userData,
					invoices: props.userData.invoices.concat(
						getPlaceholderInvoice(file.file.name, response.invoice_pid)
					),
				};
				props.setUserData(userData);
				setFilesUpload([]);
			} catch (error) {
				console.error("Error handling create:", error);
			}
		}
		props.setHidden(true);
	};

	const onFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files) {
			const fileReaders: Promise<FileWithBuffer>[] = [];

			Array.from(files).forEach((file) => {
				const reader = new FileReader();

				const fileReaderPromise = new Promise<FileWithBuffer>((resolve) => {
					reader.onload = (readEvent: ProgressEvent<FileReader>) => {
						resolve({
							file: file,
							arrayBuffer: readEvent.target?.result as ArrayBuffer,
						});
					};
				});

				reader.readAsArrayBuffer(file);
				fileReaders.push(fileReaderPromise);
			});

			Promise.all(fileReaders).then((newFiles) => {
				setFilesUpload((prevFiles) => [...prevFiles, ...newFiles]);
			});
		}
	};

	return (
		<>
			<Dialog
				hidden={props.hidden}
				onDismiss={() => props.setHidden(true)}
				dialogContentProps={{
					title: "Create new Invoice",
					subText: "Please upload the Invoice file(s)",
				}}
				modalProps={{
					isBlocking: true,
				}}
			>
				<TextField
					resizable={false}
					borderless
					value={filesUpload.map((f) => f.file.name).join("\n")}
					readOnly
					autoAdjustHeight
					multiline
				/>
				<input
					type="file"
					id="fileInput"
					accept=".pdf"
					style={{ display: "none" }}
					onChange={onFilesChange}
					required={true}
					multiple
				/>
				<label htmlFor="fileInput">
					<PrimaryButton
						text="Select files"
						onClick={() => document.getElementById("fileInput").click()}
					/>
				</label>
				<DialogFooter>
					<PrimaryButton onClick={handleSave} text="Scan" />
					<DefaultButton
						onClick={() => {
							props.setHidden(true);
							setFilesUpload([]);
						}}
						text="Cancel"
					/>
				</DialogFooter>
			</Dialog>
		</>
	);
};
