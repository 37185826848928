import { useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { getUserData } from "../../api";
import { InvoicesChart } from "../../components/InvoicesChart";
import { CostSummary } from "../../components/CostSummary";

import styles from "./Usage.module.css";

const groupByDate = (invoices) => {
	const groupedData = {};
	const today = new Date();
	const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

	// Initialize each day of the current month with zero values
	for (let d = firstDayOfMonth; d <= today; d.setDate(d.getDate() + 1)) {
		const date =
			d.getDate() +
			" " +
			d.toLocaleString("default", { month: "short" }) +
			" " +
			d.getFullYear();
		groupedData[date] = { count: 0, itemSum: 0 };
	}

	// Process each invoice
	invoices.forEach((i) => {
		const d = new Date(i.created_at);
		const date =
			d.getDate() +
			" " +
			d.toLocaleString("default", { month: "short" }) +
			" " +
			d.getFullYear();

		// Check if the date is within the current month
		if (groupedData.hasOwnProperty(date)) {
			groupedData[date].count += 1;
			groupedData[date].itemSum += i.items.length;
		}
		// No 'else' block needed as we're not handling 'earlier' invoices
	});

	return groupedData;
};

const Usage = () => {
	const { userData, setUserData } = useUser();

	async function fetchData() {
		try {
			const data = await getUserData();
			setUserData(data);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.chartsContainer}>
				<div className={styles.usageChartsContainer}>
					<div className={styles.usageInvoicesChartContainer}>
						<InvoicesChart
							invoices={groupByDate(userData.invoices)}
						></InvoicesChart>
					</div>
					{/* Other usage charts */}
				</div>
				<div className={styles.usageCostsContainer}>
					<div className={styles.usageCostsSummaryContainer}>
						<CostSummary
							invoices={groupByDate(userData.invoices)}
							limit={userData.limit}
							invoiceCost={userData.invoice_cost}
							itemCost={userData.item_cost}
						></CostSummary>
					</div>
					{/* Other cost tables */}
				</div>
			</div>
		</div>
	);
};

export default Usage;
