import { useState } from "react";

import * as interfaces from "../components/interfaces";

import { IUser } from "../components/interfaces";

const getHeaders = (contentType: string | null = null) => {
	const token = localStorage.getItem("token");
	const headers = {
		"Access-Control-Allow-Origin": "*",
	};
	if (contentType) {
		headers["Content-Type"] = contentType;
	}
	if (token) {
		headers["Authorization"] = `Bearer ${token}`;
	}
	return headers;
};

const callAPI = async (method, endpoint, headers, body = {}) => {
	const url = `/api/v1/${endpoint}`;
	const options = {
		method: method,
		headers: headers,
	};
	if (["POST", "PUT", "DELETE"].includes(method)) {
		if (body instanceof FormData) {
			options["body"] = body;
		} else options["body"] = JSON.stringify(body);
	}
	const response = await fetch(url, options);

	if (!response.ok) {
		const htmlText = await response.text();
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlText, "text/html");
		const pElement = doc.querySelector("p");
		const errorMessage = pElement ? pElement.textContent : "Unknown error";
		throw new Error(errorMessage);
	}
	return response;
};

export async function getUserData(): Promise<IUser> {
	const response = await callAPI("GET", "user", getHeaders("json"));

	const parsedResponse = await response.json();
	if (response.status > 299 || !response.ok) {
		console.log("error", response);
		return parsedResponse;
	}
	return parsedResponse;
}

export async function loginAPI(
	request: interfaces.ILoginRequest
): Promise<interfaces.ILoginResponse> {
	let formBody = [];
	formBody.push("grant_type=");
	formBody.push(
		encodeURIComponent("username") + "=" + encodeURIComponent(request.username)
	);
	formBody.push(
		encodeURIComponent("password") + "=" + encodeURIComponent(request.password)
	);
	formBody.push("scope=");
	formBody.push("client_id=");
	formBody.push("client_secret=");
	const response = await callAPI(
		"POST",
		"login",
		getHeaders("application/x-www-form-urlencoded"),
		formBody.join("&")
	);
	const parsedResponse = await response.json();
	localStorage.setItem("token", parsedResponse.access_token);
	return parsedResponse;
}

export async function logoutAPI(): Promise<interfaces.ILoginResponse> {
	const response = await callAPI("POST", `logout`, getHeaders("json"), {});
	const parsedResponse = await response.json();
	return parsedResponse;
}

export async function createInvoiceApi(
	request: interfaces.ICreateInvoiceRequest
): Promise<interfaces.ICreateInvoiceResponse> {
	const formData = new FormData();
	formData.append(
		"file",
		new Blob([request.content], { type: "application/pdf" }),
		request.filename
	);
	// Assuming request.notes is already a string
	const response = await callAPI(
		"POST",
		"create_invoice",
		getHeaders(),
		formData
	);
	const parsedResponse = await response.json();
	return parsedResponse;
}

export async function processInvoiceApi(
	request: interfaces.IProcessInvoiceRequest
): Promise<Response> {
	const response = await callAPI(
		"POST",
		"process_invoice",
		getHeaders("application/json"),
		request
	);
	const parsedResponse = await response.json();
	return parsedResponse;
}

export async function updateInvoiceApi(
	request: interfaces.IInvoice
): Promise<Response> {
	const response = await callAPI(
		"PUT",
		"invoice",
		getHeaders("application/json"),
		request
	);
	return response;
}

export async function deleteInvoiceApi(
	request: interfaces.IProcessInvoiceRequest
): Promise<Response> {
	const response = await callAPI(
		"DELETE",
		"invoice?pid=" + request.invoice_pid,
		getHeaders()
	);
	const parsedResponse = await response.json();
	return parsedResponse;
}

export async function readInvoiceApi(
	request: interfaces.IProcessInvoiceRequest
): Promise<Response> {
	const response = await callAPI(
		"PUT",
		"read",
		getHeaders("application/json"),
		request
	);
	const parsedResponse = await response.json();
	return parsedResponse;
}

export async function downloadFileApi(
	request: interfaces.IFileDownloadRequest
) {
	// Fetch the file
	const response = await callAPI(
		"GET",
		`download_invoice?pid=${request.pid}`,
		getHeaders()
	);
	const blob = await response.blob();
	// Create a new URL pointing to the blob object
	const blobUrl = window.URL.createObjectURL(blob);
	// Create a link element
	const link = document.createElement("a");
	// Set link's href to point to the blob URL
	link.href = blobUrl;
	link.download = request.filename.split(".")[0] + "." + request.file_extension;
	// Append link to the body
	document.body.appendChild(link);
	// Dispatch click event on the link
	// This is necessary as link.click() does not work on the latest firefox
	link.dispatchEvent(
		new MouseEvent("click", {
			bubbles: true,
			cancelable: true,
			view: window,
		})
	);
	// Remove link from body
	document.body.removeChild(link);
}

export async function getTariff(
	code: string
): Promise<interfaces.ITariffRecord[]> {
	const response = await callAPI("GET", "tariff?code=" + code, getHeaders());
	const data = await response.json();
	return data;
}

export async function registerAPI(request) {
	const response = await callAPI(
		"POST",
		"register", // Assuming the endpoint for registration is '/register'
		getHeaders("application/json"),
		request
	);

	const parsedResponse = await response.json();

	// Handle successful registration here. Depending on your API,
	// you might want to automatically log in the user, or direct them to a verification process.

	return parsedResponse;
}
