import { useState, useEffect } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";
import { DefaultButton } from "@fluentui/react";
import { useUser } from "../../contexts/UserContext";
import styles from "./Layout.module.css";
import logo from "../../assets/top-logo.png";
import easyFreightLogo from "../../assets/easyfreight_full.svg";
import { RegisterDialog } from "../../components/RegisterDialog";
import { LoginButton } from "../../components/LoginButton";
import { LoginDialog } from "../../components/LoginDialog";

const Layout = () => {
	const [isLogged, setIsLogged] = useState(false);
	const [showLoginDialog, setShowLoginDialog] = useState(false);
	const { userData, setUserData } = useUser();
	const [showRegisterDialog, setShowRegisterDialog] = useState(false);

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			setIsLogged(true);
		}
	}, []);

	// Create a context

	return (
		<div className={styles.layout}>
			<LoginDialog
				visible={showLoginDialog}
				setIsLogged={setIsLogged}
				setVisible={setShowLoginDialog}
				setUserData={setUserData}
			/>
			<RegisterDialog
				visible={showRegisterDialog}
				setVisible={setShowRegisterDialog}
			/>
			<header className={styles.header} role={"banner"}>
				<div className={styles.headerContainer}>
					<Link to="/" className={styles.headerTitleContainer}>
						<img src={logo} className={styles.headerLogo} alt="logo" />
					</Link>
					{isLogged && userData && (
						<nav>
							<ul className={styles.headerNavList}>
								<li>
									<NavLink
										to="/"
										className={({ isActive }) =>
											isActive && isLogged
												? styles.headerNavPageLinkActive
												: styles.headerNavPageLink
										}
									>
										Invoices
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/usage"
										className={({ isActive }) =>
											isActive && isLogged
												? styles.headerNavPageLinkActive
												: styles.headerNavPageLink
										}
									>
										Usage
									</NavLink>
								</li>
							</ul>
						</nav>
					)}{" "}
					<div>
						<LoginButton
							isLogged={isLogged && userData}
							setIsLogged={setIsLogged}
							setShowLoginDialog={setShowLoginDialog}
						/>
						{!isLogged && (
							<DefaultButton
								text={"Register"}
								className={styles.registerButton}
								onClick={() => {
									setShowRegisterDialog(!showRegisterDialog);
								}}
							/>
						)}
					</div>
				</div>
			</header>
			{isLogged && userData ? <Outlet /> : <div>Not logged in</div>}
			<footer className={styles.footer}>
				<h6 className={styles.headerRightText}>
					<a
						href="https://easyfreight.co.nz/"
						target={"_blank"}
						title="easyfreight link"
					>
						<img
							src={easyFreightLogo}
							className={styles.footerLogo}
							alt="logo"
						/>
					</a>
				</h6>
			</footer>
		</div>
	);
};

export default Layout;
