import React from "react";
import styles from "./LoginDialog.module.css";
import { loginAPI } from "../../api";
import {
	Dialog,
	DialogFooter,
	DefaultButton,
	TextField,
	MessageBar,
	MessageBarType,
} from "@fluentui/react";

export const LoginDialog = ({
	visible,
	setVisible,
	setIsLogged,
	setUserData,
}) => {
	const [username, setUsername] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [errors, setErrors] = React.useState([]);
	const validateForm = () => {
		let errors = [];
		if (!username) {
			errors.push("Username is required");
		}
		if (!password) {
			errors.push("Password is required");
		}
		if (errors.length > 0) {
			setErrors(["Failed to authenticate! Check your credentials."]);
			return false;
		}
		return true;
	};
	const reset = () => {
		setUsername("");
		setPassword("");
		setErrors([]);
		setVisible(false);
	};
	const handleLogin = async () => {
		try {
			if (!validateForm()) {
				return;
			}
			const response = await loginAPI({
				username: username,
				password: password,
			});
			if (response.access_token) {
				setIsLogged(true);
				if (response.user) {
					setUserData(response.user);
				}
				reset();
			} else {
				setErrors(["Incorrect credentials"]);
			}
		} catch (error) {
			console.error("Error handling create:", error);
			if (error.message.includes("UserDoesNotExist")) {
				setErrors(["User does not exist"]);
			} else {
				setErrors([error.message]);
			}
		}
	};
	return (
		<>
			<Dialog
				hidden={!visible}
				onDismiss={() => setVisible(false)}
				dialogContentProps={{
					title: "Login",
					subText: "Please provide your username and password.",
				}}
			>
				{" "}
				{errors.length > 0 &&
					errors.map((error) => (
						<MessageBar
							messageBarType={MessageBarType.error}
							isMultiline={false}
							dismissButtonAriaLabel="Close"
						>
							{error}
						</MessageBar>
					))}
				<TextField
					label="Username"
					value={username}
					onChange={(e, newValue) => setUsername(newValue)}
					required={true}
				/>
				<TextField
					label="Password"
					value={password}
					onChange={(e, newValue) => setPassword(newValue)}
					required={true}
					type={"password"}
					canRevealPassword={true}
				/>
				<DialogFooter>
					<DefaultButton onClick={handleLogin} text="Log In" />
					<DefaultButton onClick={() => reset()} text="Cancel" />
				</DialogFooter>
			</Dialog>
		</>
	);
};
