import React from "react";
import {
	RadialBarChart,
	RadialBar,
	ResponsiveContainer,
	Tooltip,
} from "recharts";
import { DefaultButton } from "@fluentui/react";

import styles from "./CostSummary.module.css";

interface ICostSummaryProps {
	invoices: {
		[key: string]: {
			count: number;
			itemSum: number;
		};
	};
	limit?: number;
	invoiceCost?: number;
	itemCost?: number;
}

const CustomTooltip = ({ active, payload, index }) => {
	if (active && payload && payload.length) {
		return (
			<div
				className="custom-tooltip"
				style={{
					backgroundColor: "#fff",
					padding: "10px",
					border: "1px solid #ccc",
				}}
			>
				<p>{payload[0].payload.name}</p>
				<p>Value: ${payload[0].value}</p>
			</div>
		);
	}

	return null;
};

export class CostSummary extends React.Component<ICostSummaryProps> {
	constructor(props) {
		super(props);
		this.state = {
			invoices: props.invoices,
			// ... other state properties ...
		};
	}
	processData = () => {
		let totalCount = 0;
		let totalItemSum = 0;

		Object.values(this.props.invoices).forEach((invoice) => {
			totalCount += invoice.count;
			totalItemSum += invoice.itemSum;
		});

		return {
			costFromInvoices: totalCount * this.props.invoiceCost || 0,
			costFromItems: totalItemSum * this.props.itemCost || 0,
		};
	};

	public render() {
		const data = this.processData();
		const totalCost = [
			{
				name: "From Items",
				value: data.costFromItems,
				fill: "#8dd1e1",
				hide: false,
			},
			{
				name: "From Invoices",
				value: data.costFromInvoices,
				fill: "#83a6ed",
				hide: false,
			},
			{
				name: "Total Spent",
				value: data.costFromInvoices + data.costFromItems,
				fill: "#8884d8",
				hide: false,
			},
			{
				name: "Limit",
				value: this.props.limit,
				fill: "#ffffff",
				hide: true,
			},
		];
		return (
			<div className={styles.rootStyle}>
				<h4 className={styles.chartTitle}>Cost Summary</h4>
				<div className={styles.chartContainer}>
					<ResponsiveContainer className={styles.chartWrapper}>
						<RadialBarChart
							className={styles.chart}
							innerRadius="30%"
							outerRadius="80%"
							data={totalCost}
							startAngle={180}
							endAngle={0}
							barSize={this.props.limit}
							margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
						>
							<RadialBar
								label={{
									fill: "#666",
									position: "insideStart",
									fontSize: "10px",
									formatter: (val) => `$${val}`,
								}}
								background={{ fill: "#eee" }}
								dataKey="value"
							/>
							<Tooltip content={CustomTooltip} />
						</RadialBarChart>
					</ResponsiveContainer>
					<div className={styles.costSummary}>
						<h3>Spent</h3>
						<text className={styles.textTotal}>
							${data.costFromInvoices + data.costFromItems}
						</text>
						<text className={styles.textLimit}>/${this.props.limit}</text>
						<DefaultButton
							onClick={() => {}}
							text="Increase Limit"
							className={styles.increaseLimitButton}
						/>
					</div>
				</div>
			</div>
		);
	}
}
