import { useState } from "react";
import { DefaultButton, Dialog } from "@fluentui/react";
import { registerAPI } from "../../api";
import styles from "./RegisterDialog.module.css";

import {
	TextField,
	Checkbox,
	PrimaryButton,
	Stack,
	MessageBar,
	MessageBarType,
} from "@fluentui/react";

const RegistrationForm = () => {
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		username: "",
		password: "",
		email: "",
		is_active: true,
		is_admin: false,
		limit: 100,
		invoice_cost: 2,
		item_cost: 0.5,
	});

	const handleChange = (event, newValue) => {
		const { name, type } = event.target;
		const value = type === "number" ? Number(newValue) : newValue;
		setFormData({ ...formData, [name]: value });
	};

	const handleCheckboxChange = (name) => (_, checked) => {
		setFormData({ ...formData, [name]: checked });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setError("");
		try {
			const response = await registerAPI(formData);
			setSuccess(true);
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Stack>
				<TextField
					label="First Name"
					name="first_name"
					required
					onChange={handleChange}
					className={styles.textField}
					value={formData.first_name}
				/>
				<TextField
					label="Last Name"
					name="last_name"
					required
					onChange={handleChange}
					className={styles.textField}
					value={formData.last_name}
				/>
				<TextField
					label="Email"
					name="email"
					type="email"
					required
					onChange={handleChange}
					className={styles.textField}
					value={formData.email}
				/>
				<TextField
					label="Username"
					name="username"
					required
					onChange={handleChange}
					className={styles.textField}
					value={formData.username}
				/>
				<TextField
					label="Password"
					type="password"
					name="password"
					canRevealPassword
					required
					onChange={handleChange}
					className={styles.textField}
					value={formData.password}
				/>
			</Stack>
			{/* <Stack>
				<TextField
					label="Limit"
					name="limit"
					type="number"
					required
					onChange={handleChange}
					className={styles.textField}
					value={formData.limit.toString()}
					step={10}
				/>
				<TextField
					label="Invoice Cost"
					name="invoice_cost"
					type="number"
					required
					onChange={handleChange}
					className={styles.textField}
					value={formData.invoice_cost.toString()}
					step={0.1}
				/>
				<TextField
					label="Item Cost"
					name="item_cost"
					type="number"
					required
					onChange={handleChange}
					className={styles.textField}
					value={formData.item_cost.toString()}
					step={0.1}
				/>
				<div className={styles.checkboxContainer}>
					<span className={styles.checkboxLabel}>Is Active</span>
					<Checkbox
						onChange={handleCheckboxChange("is_active")}
						checked={formData.is_active}
					/>
				</div>
				<div className={styles.checkboxContainer}>
					<span className={styles.checkboxLabel}>Is Admin</span>
					<Checkbox
						onChange={handleCheckboxChange("is_admin")}
						checked={formData.is_admin}
					/>
				</div>
			</Stack> */}
			<DefaultButton
				type="submit"
				text="Register"
				className={styles.submitButton}
			/>
			{error && (
				<MessageBar messageBarType={MessageBarType.error}>
					Error `${error}`
				</MessageBar>
			)}
			{success && (
				<MessageBar messageBarType={MessageBarType.success}>Success</MessageBar>
			)}
		</form>
	);
};

export const RegisterDialog = ({ visible, setVisible }) => {
	return (
		<Dialog
			hidden={!visible}
			onDismiss={() => setVisible(false)}
			dialogContentProps={{
				title: "Register",
				subText: "Please fill out the registration form.",
			}}
		>
			<h3>Register</h3>
			<RegistrationForm />
		</Dialog>
	);
};
